// MAIN
$primary-color: white;
$secondary-color: rgb(19, 66, 116);
$tertiary-color: rgb(233, 233, 233);
$fourth-color: rgb(19, 41, 65);
$fifth-color: rgb(25, 81, 138);

// LANGAGE
$css-color: #2091eb;
$html-color: #e54d26;
$java-color: red;
$js-color: #f7df1e;
$php-color: #787cb5;
$ts-color: #007acc;

// LIBRARY
$angular-color: #c3002f;
$bootstrap-color: #6f43a3;
$jquery-color: #4ad5ff;
$node-color: #83cd29;
$npm-color: #cc3534;
$react-color: #4ad5ff;
$sass-color: #cd679a;

// TOOLS
$confluence-color: #2684ff;
$figma-color: #a259ff;
$git-color: #de4c36;
$illustrator-color: #ff9a00;
$jira-color: #0255cf;
$notion-color: black;
$photoshop-color: #00c2f7;
$trello-color: #207cf7;
