@keyframes popUpSlide {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
  }
  20% {
    opacity: 1;
    visibility: visible;
    transform: translateY(10%);
  }
  80% {
    opacity: 1;
    visibility: visible;
    transform: translateY(10%);
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
  }
}

@keyframes fadeOutBackground {
  from {
    opacity: 0.5;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.2;
  }
  75% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes backgroundAnimatedDesktop {
  from {
    opacity: 0.7;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@-o-keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translate3d(0, -0.5em, 0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

@keyframes skillsSelector {
  from {
    opacity: 0;
    transform: translateY(-20px);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
  }
}

@keyframes scaleAngles {
  0% {
    transform: scale(0.88);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes animationTriangle1 {
  from {
    background-color: rgba(255, 255, 255);
    opacity: 0.3;
  }
  20% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    background-color: rgba(255, 255, 255);
    opacity: 0.3;
  }
}

@keyframes blinkingTriangle1 {
  from {
    background-color: rgba(255, 255, 255);
    opacity: 0.4;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    background-color: rgba(255, 255, 255);
    opacity: 0.4;
  }
}

@keyframes animationTriangle2 {
  from {
    background-color: rgba(255, 255, 255);
    opacity: 0.5;
  }
  20% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    background-color: rgba(255, 255, 255);
    opacity: 0.5;
  }
}

@keyframes blinkingTriangle2 {
  from {
    background-color: rgba(255, 255, 255);
    opacity: 0.2;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    background-color: rgba(255, 255, 255);
    opacity: 0.2;
  }
}

@keyframes animationTriangle3 {
  from {
    background-color: rgba(255, 255, 255);
    opacity: 0.4;
  }
  20% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.6;
  }
  100% {
    background-color: rgba(255, 255, 255);
    opacity: 0.4;
  }
}

@keyframes blinkingTriangle3 {
  from {
    background-color: rgba(255, 255, 255);
    opacity: 0.4;
  }
  15% {
    opacity: 0.2;
  }
  35% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    background-color: rgba(255, 255, 255);
    opacity: 0.4;
  }
}

@keyframes animationTriangle4 {
  from {
    background-color: rgba(255, 255, 255);
    opacity: 0.2;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
  100% {
    background-color: rgba(255, 255, 255);
    opacity: 0.2;
  }
}

@keyframes blinkingTriangle4 {
  from {
    background-color: rgba(255, 255, 255);
    opacity: 0;
  }
  35% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  65% {
    opacity: 0.8;
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
    opacity: 0;
  }
}

@keyframes blinkingLogo {
  from {
    opacity: 1;
  }
  40% {
    opacity: 0.8;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appearAndBlink {
  from {
    filter: grayscale(1);
    visibility: hidden;
    opacity: 0;
  }
  20% {
    visibility: visible;
    opacity: 1;
  }
  25% {
    visibility: hidden;
    opacity: 0;
  }
  27% {
    visibility: visible;
    opacity: 1;
  }
  72% {
    visibility: visible;
    opacity: 1;
  }
  76% {
    visibility: hidden;
    opacity: 0;
  }
  77% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    filter: inherit;
    visibility: visible;
    opacity: 1;
  }
}

@keyframes appearAndBlink2 {
  from {
    filter: grayscale(1);
    visibility: hidden;
    opacity: 0;
  }
  25% {
    visibility: hidden;
    opacity: 0;
  }
  40% {
    visibility: visible;
    opacity: 1;
  }
  70% {
    filter: grayscale(0);
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 0.8;
  }
}

@keyframes titleShowUp {
  from {
    filter: blur(3px);
    opacity: 0;
    transform: translate3d(0, 0, 0), scale(1.03);
    visibility: hidden;
  }
  30% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  50% {
    filter: blur(0px);
    transform: translate3d(0, 0, 0), scale(1.03);
  }
  60% {
    opacity: 0.2;
  }
  70% {
    filter: blur(1px);
    transform: translate3d(0, 0, 0), scale(1.03);
    opacity: 1;
  }
  80% {
    filter: inherit;
    opacity: 0.8;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes titleEffect {
  from {
    opacity: 1;
    filter: inherit;
    text-shadow: -15px 0px 1px rgba(0, 0, 0, 1);
  }
  80% {
    filter: inherit;
  }
  85% {
    filter: blur(0.5px);
  }
  90% {
    filter: blur(1px);
  }
  95% {
    filter: blur(0.5px);
  }
  100% {
    filter: inherit;
  }
}

@keyframes titleEffectMobile {
  from {
    filter: blur(2px);
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  }
  5% {
    filter: blur(0px);
    opacity: 1;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  }
  6% {
    opacity: 0.5;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  }
  8% {
    opacity: 1;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  }
}

@keyframes btnTitleEffect {
  from {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lineSizeDesktop {
  from {
    width: 0%;
  }
  100% {
    width: 50%;
  }
}

@keyframes lineSizeMobile {
  from {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes scaleModal {
  from {
    transform: scaleY(0) scaleX(0) translate(-50%, -50%);
  }
  100% {
    transform: scaleY(1) scaleX(1) translate(-50%, -50%);
  }
}

@keyframes infoSlideTop {
  from {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
