@font-face {
  font-family: "Roboto";
  font-weight: 100;
  src: local("MyFont"),
    url("../assets/fonts/roboto_thin.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: local("MyFont"),
    url("../assets/fonts/roboto_light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: local("MyFont"),
    url("../assets/fonts/roboto_regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local("MyFont"),
    url("../assets/fonts/roboto_medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Minalis";
  src: local("MyFont"), url("../assets/fonts/minalis.otf") format("opentype");
  font-display: auto;
}
