@import "../utils/variables.scss";

// CONTAINER
.frame {
  align-items: center;
  background-color: transparent;
  border: 0px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 60px;
  justify-content: center;
  justify-items: center;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1;
  // ANIMATION
  animation: appearAndBlink 1s ease-in forwards;
  .angles {
    position: absolute;
    background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.7) 2px,
          transparent 2px
        )
        0 0,
      // TOP LEFT
      linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 2px, transparent 2px) 0 0,
      // TOP LEFT
      linear-gradient(to left, rgba(0, 0, 0, 0.7) 2px, transparent 2px) 100% 0,
      // TOP RIGHT
      linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 2px, transparent 2px) 100% 0,
      // TOP RIGHT
      linear-gradient(to left, rgba(0, 0, 0, 0.7) 2px, transparent 2px) 100%
        100%,
      // BOTTOM RIGHT
      linear-gradient(to top, rgba(0, 0, 0, 0.7) 2px, transparent 2px) 100% 100%,
      // BOTTOM RIGHT
      linear-gradient(to right, rgba(0, 0, 0, 0.7) 2px, transparent 2px) 0 100%,
      // BOTTOM LEFT
      linear-gradient(to top, rgba(0, 0, 0, 0.7) 2px, transparent 2px) 0 100%;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    bottom: 0;
    left: 0;
    opacity: 0;
    right: 0;
    top: 0;
  }
  .triangle {
    content: "";
    grid-column: 1/1;
    grid-row: 1/1;
    height: 80%;
    position: relative;
    width: 93%;
    z-index: 2;
  }
  button {
    align-items: center;
    border: 0;
    color: $primary-color;
    display: grid;
    font-size: 1em;
    grid-column: 1/1;
    grid-row: 1/1;
    height: 80%;
    justify-content: center;
    letter-spacing: 0.1em;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-overflow: ellipsis;
    width: 93%;
    z-index: 3;
    .push {
      margin-left: 30%;
      width: 100%;
    }
  }
  &::before {
    background-color: $secondary-color;
    content: "";
    grid-column: 1/1;
    grid-row: 1/1;
    height: 80%;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 93%;
    z-index: 2;
  }
  // HOVER
  &:hover {
    &::before {
      transition: all 0.1s;
      background-color: $fifth-color;
      box-shadow: 0 0 3px 0px grey;
    }
    .angles {
      // ANIMATION
      animation: scaleAngles 0.2s ease-in;
      animation-fill-mode: forwards;
      transform-origin: center;
    }
  }
}

@media all and (max-width: 1024px) {
  // CONTAINER
  .frame {
    .angles {
      display: none;
      visibility: hidden;
    }
    button {
      .push {
        margin: 0;
      }
    }
    // HOVER
    &:hover {
      &::before {
      }
      .angles {
      }
    }
  }
}
