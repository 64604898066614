@import "./../../utils/variables.scss";

#error {
  justify-content: center;
  * {
    opacity: 0;
    visibility: hidden;
    //ANIMATION
    animation: titleShowUp 1s ease-in forwards;
  }
  h1 {
    text-align: center;
    letter-spacing: 0.1em;
    font-weight: 300;
  }
  button {
    font-size: 2em;
  }
  button:hover {
    svg {
      color: $fifth-color;
    }
  }
}

@media all and (max-width: 1024px) {
  #error {
    padding: 0;
    h1 {
      width: 100%;
      font-size: 1em;
    }
  }
}
