@import "./../../utils/variables.scss";

header {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  max-height: 70px;
  height: 5vh;
  padding: 0 15%;
  width: 100%;
  z-index: 100;
  img {
    display: flex;
    height: 4em;
    margin: 0;
    object-fit: contain;
    padding: 0;
    width: auto;
  }
  img:hover {
    //ANIMATION
    animation: blinkingLogo 1s infinite;
    filter: invert(13%) sepia(12%) saturate(2642%) hue-rotate(171deg)
      brightness(100%) contrast(96%);
  }
  div {
    column-gap: 1em;
    display: flex;
    button {
      cursor: pointer;
      height: 4em;
      margin: 0;
      padding: 0;
      svg {
        font-size: 2em;
      }
      :hover {
        color: $fifth-color;
        //ANIMATION
        animation: blinkingLogo 1s infinite;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  header {
    box-shadow: 0 0px 5px -1px grey;
    height: 5vh;
    min-height: 50px;
    padding: 0 7.5%;
    position: relative;
    img {
      height: 3em;
    }
    div {
      button {
        height: 3em;
        svg {
          font-size: 1.5em;
        }
        :hover {
          color: $fifth-color;
        }
      }
    }
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  header {
    height: 4.5vh;
    min-height: 45px;
    img {
      height: 2.8em;
    }
    div {
      button {
        height: 2.8em;
        svg {
          font-size: 1.4em;
        }
        :hover {
          color: $fifth-color;
        }
      }
    }
  }
}
