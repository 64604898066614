@import "./utils/variables.scss";

html {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  * {
    scroll-behavior: smooth;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased;
  }
  body {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    letter-spacing: 0.025em;
    margin: 0;
    overflow: hidden;
    text-rendering: optimizeLegibility;
    width: 100%;
  }
  main {
    display: flex;
    flex-direction: column;
    height: 95vh;
    position: absolute;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        320deg,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.6) 65%
      ),
      url("../public/images/backgrounds/bg1.webp");
    section {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      position: relative;
      width: 100%;
      background: rgba(255, 255, 255, 0.7);
      .container {
        align-items: center;
        display: flex;
        flex-direction: column;
        overflow: hidden auto;
        width: 100%;
        height: inherit;
      }
      .title::before {
        background: black;
        content: "";
        display: block;
        height: 7px;
        width: 7px;
      }
      .title {
        display: flex;
        flex-direction: column;
        min-height: 10%;
        justify-content: flex-end;
        width: 70%;
        .line {
          border-top: 1px solid black;
          content: "";
          display: block;
          // ANIMATION
          animation: lineSizeDesktop 1s ease-in-out forwards;
          width: 0%;
        }
        h1 {
          font-size: 3em;
          font-weight: 300;
          letter-spacing: 0.5em;
          margin: 0;
          margin-top: 1vh;
          opacity: 0;
          visibility: hidden;
          // ANIMATION
          animation: titleShowUp 1s ease forwards;
        }
      }
      .infos {
        align-items: center;
        background-color: $primary-color;
        column-gap: 1em;
        display: flex;
        height: 15%;
        justify-content: flex-end;
        max-height: 165px;
        padding-right: 15%;
        width: 100%;
        z-index: 100;
        p {
          font-size: 1.2em;
          letter-spacing: 0.05em;
          font-weight: 300;
          margin: 0;
        }
        span {
          svg {
            background-color: $fourth-color;
            color: white;
            padding: 10px;
            position: relative;
          }
        }
      }
    }
  }
  h2,
  h3,
  h4 {
    letter-spacing: 0.05em;
    font-weight: 300;
  }
  a,
  ul,
  li {
    color: inherit;
    list-style: none;
    text-decoration: none;
  }
  p,
  ul,
  li {
    color: rgb(23, 23, 23);
    font-weight: 100;
  }
  button {
    font-size: 1em;
    font-weight: 500;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media all and (max-width: 1024px) {
  html {
    body {
      main {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.3) 20%,
          rgba(255, 255, 255, 0.5) 90%
        );
        overflow: hidden;
        #background {
          width: auto;
        }
        section {
          justify-content: space-between;
          padding-bottom: 25px;
          .container,
          form {
            div {
              padding: 0;
            }
          }
          .container {
            height: 100%;
            row-gap: 25px;
            padding-bottom: 100px;
            .title::before {
              height: 5px;
              width: 5px;
            }
            .title {
              min-height: auto;
              width: 85%;
              height: auto;
              align-items: center;
              padding-top: 25px;
              .line {
                // ANIMATION
                animation: lineSizeMobile 0.5s ease-in-out forwards;
              }
              h1 {
                font-size: 1em;
              }
            }
          }
          .infos {
            align-content: center;
            align-items: center;
            box-shadow: 0 0px 5px -1px grey;
            column-gap: 0.5em;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            height: 50px;
            justify-content: center;
            padding: 0;
            position: fixed;
            bottom: 0px;
            border: inherit;
            p {
              font-size: 0.8em;
              display: flex;
              text-align: center;
              justify-content: center;
              flex-direction: column;
            }
            span:nth-child(2) {
              display: none;
            }
          }
        }
        .loading {
          h1 {
            font-size: 1em;
          }
        }
      }
    }
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  html {
    main {
      section {
        .container {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 85%;
          overflow: hidden auto;
          width: 100%;
        }
        .title::before {
          height: 5px;
          width: 5px;
        }
        .title {
          min-height: 12%;
          h1 {
            font-size: 2em;
            letter-spacing: 0.35em;
          }
        }
        .infos {
          max-height: 100px;
          p {
            font-size: 0.9em;
            letter-spacing: 0.05em;
            font-weight: 300;
            margin: 0;
            span {
              font-size: 0.9em;
            }
          }
          span {
            svg {
              background-color: $fourth-color;
              color: white;
              padding: 5px;
              position: relative;
            }
          }
        }
      }
    }
  }
}
