@import "./../../utils/variables.scss";

#connexion {
  display: flex;
  flex-direction: column;
  height: 95vh;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
  .overlay {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: center;
    opacity: 0;
    z-index: 1;
    // ANIMATION
    animation: titleShowUp 0.5s ease-in forwards;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    .profil {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 5% 0;
      * {
        display: flex;
        z-index: 1;
        font-family: "Minalis";
        font-weight: normal !important;
        margin: 0;
        padding: 0;
        text-align: center;
      }
      .glitch {
        position: relative;
        p {
          z-index: -1;
        }
        .line {
          font-size: 4em;
          &:not(:first-child) {
            left: 0;
            position: absolute;
            top: 0;
          }
          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              animation: clip 3000ms $i * -300ms linear infinite,
                glitch#{$i} 1900ms random(1000) * -1ms linear infinite;
              @keyframes clip {
                0% {
                  clip-path: polygon(0 100%, 100% 100%, 100% 120%, 0 120%);
                }
                100% {
                  clip-path: polygon(0 -20%, 100% -20%, 100% 0%, 0 0);
                }
              }
              @keyframes glitch#{$i} {
                80% {
                  transform: translateX(0);
                  color: black;
                }
                85% {
                  transform: translateX(random(10) - 5px);
                  color: $secondary-color;
                }
                90% {
                  transform: translateX(random(10) - 3px);
                  color: transparent;
                }
                95% {
                  transform: translateX(random(10) - 5px);
                  color: $secondary-color;
                }
                100% {
                  transform: translateX(0);
                  color: black;
                }
              }
            }
          }
        }
      }
      h2 {
        letter-spacing: -0.025em;
        font-size: 5em;
      }
    }
    .frame {
      max-width: 375px;
      width: 70vh;
      button {
        display: inline-block;
        font-size: 1.5em;
        color: white;
        letter-spacing: 0.25em;
      }
      :nth-child(3) {
        clip-path: polygon(0 100%, 10% 0, 0 0);
        -webkit-clip-path: polygon(0 100%, 10% 0, 0 0);
      }
      :nth-child(4) {
        clip-path: polygon(90% 0%, 100% 100%, 80% 100%);
        -webkit-clip-path: polygon(90% 0%, 100% 100%, 80% 100%);
      }
      :nth-child(5) {
        clip-path: polygon(90% 0%, 70% 0%, 80% 100%);
        -webkit-clip-path: polygon(90% 0%, 70% 0%, 80% 100%);
      }
      :nth-child(6) {
        clip-path: polygon(10% 0%, 30% 0%, 20% 100%);
        -webkit-clip-path: polygon(10% 0%, 30% 0%, 20% 100%);
      }
      :nth-child(7) {
        clip-path: polygon(-10% 0%, 10% 0%, 20% 100%, 0% 100%);
        -webkit-clip-path: polygon(-10% 0%, 10% 0%, 20% 100%, 0% 100%);
      }
      :nth-child(8) {
        clip-path: polygon(100% 0, 90% 0, 100% 100%);
        -webkit-clip-path: polygon(100% 0, 90% 0, 100% 100%);
      }
    }
  }
  h3 {
    font-weight: 300;
    letter-spacing: 0.5em;
    margin: 0;
    text-align: center;
    width: 100%;
  }
  .background {
    height: 100%;
    left: 0;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        320deg,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.6) 65%
      ),
      url("../../../public/images/backgrounds/bg2.webp");
    // ANIMATION
    animation: backgroundAnimatedDesktop 10s infinite;
  }
}

@media all and (max-width: 1024px) {
  #connexion {
    .overlay {
      height: auto;
      padding-bottom: 50px;
      .profil {
        margin: 10% 0;
        .glitch {
          position: relative;
          .line {
            font-size: 1.75em !important;
            letter-spacing: 0px;
          }
        }
        h2 {
          font-size: 2em;
          letter-spacing: 0px;
        }
      }
      .frame {
        max-height: 45px;
        max-width: 250px;
        button {
          font-size: 0.8em;
        }
      }
    }
    h3 {
      position: fixed;
      bottom: 5%;
      font-size: 1em;
      letter-spacing: 0.25em;
      z-index: 1;
    }
    .background {
      background-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 20%,
          rgba(255, 255, 255, 0) 50%
        ),
        url("../../../public/images/backgrounds/bg2.webp");
    }
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  #connexion {
    .overlay {
      .profil {
        .glitch {
          .line {
            font-size: 3em !important;
          }
        }
        h2 {
          font-size: 4em;
        }
      }
      .frame {
        max-height: 50px;
        max-width: 300px;
        button {
          font-size: 1.1em;
        }
      }
    }
    h3 {
      font-size: 1em;
    }
  }
}
